<template>
  <div>
    <div v-if="!!label">{{ label }}</div>
    <b-form-input
      @input="$emit('input', $event)"
      :type="inputType"
      :placeholder="placeholder"
      @blur="format()"
      @focus="$emit('onFocus', $event)"
      @keydown.enter.stop="$emit('pressEnter', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    inputType: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  methods: {
    format() {
      this.$emit("validation");
    },
  },
};
</script>
